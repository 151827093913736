import React from 'react';
import * as S from './styles';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { TEXT_COLOR_NEUTRAL_2, SECONDARY_PURPLE_COLOR, DEFAULT_FONT_FAMILY, TEXT_COLOR_NEUTRAL_1 } from '../../../../constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm{
    border-radius: 10px;
  }
`;

const StyledMuiCheckbox = withStyles({
  root: {
    color: TEXT_COLOR_NEUTRAL_2,
    '&$checked': {
      color: SECONDARY_PURPLE_COLOR,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const DialogContents = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 0px;
  
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`
class SubmitPromptPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      again: localStorage.getItem('prompt_show_again') ? localStorage.getItem('prompt_show_again') : true,
    };
  }

  render() {
    let { visible, showAgainVisible } = this.props;
    let { again } = this.state;
    return (
      <StyledDialog open={visible} onClose={() => this.props.handleClose()}>
        <S.DialogContent>Do you want to save the project?</S.DialogContent>
        {showAgainVisible && <FormControlLabel
          style={{ padding: "15px 15px 0px 10px", margin: "unset" }}
          control={<StyledMuiCheckbox style={{padding: 'unset', margin: "0 10px"}} checked={!again} onChange={() => this.setState({again: !again})} value={!again} />}
          label={<DialogContents style={{fontSize: 13}}>Don't show again.</DialogContents>}
        />}
        <S.DialogAction>
          <S.Button onClick={() => {
            this.props.handleOk(true);
            localStorage.setItem('prompt_show_again', again);
          }}>
            Yes
          </S.Button>
          <S.Button onClick={() => {
            this.props.handleClose();
            localStorage.setItem('prompt_show_again', again);
          }}>
            No
          </S.Button>
        </S.DialogAction>
      </StyledDialog>
    )
  }
}

export default SubmitPromptPopup