import React from 'react';
import PropTypes from 'prop-types';
import { GeometryUtils } from '../../utils/export';
import Ruler from './ruler';
import { LINE_THICKNESS } from '../../../src/constants';

const STYLE = {
  stroke: "#506ab3",
  strokeWidth: "1px"
};
const STYLE_TEXT = {
  textAnchor: "middle",
  fontSize: "10px",
  fontWeight: "bold",
};

export function compareVertices(v0, v1) {
  return v0.x === v1.x ? v0.y - v1.y : v0.x - v1.x;
}

export default function Line({ line, allLine, layer, scene, num, catalog }) {
  let {showWallLengthMeasure, showWallCabinetMeasure, showBaseCabinetMeasure, showWindowDoorMeasure} = scene;
  let vertex0 = layer.vertices.get(line.vertices.get(0));
  let vertex1 = layer.vertices.get(line.vertices.get(1));
  if (vertex0.id === vertex1.id || GeometryUtils.samePoints(vertex0, vertex1)) return null; //avoid 0-length lines

  let { x: x1, y: y1 } = vertex0;
  let { x: x2, y: y2 } = vertex1;
  let thickness = LINE_THICKNESS;
  let half_thickness = thickness / 2;
  if (compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x) {
    ({ x: x1, y: y1 } = vertex1);
    ({ x: x2, y: y2 } = vertex0);
  }

  let length = GeometryUtils.pointsDistance(x1, y1, x2, y2);
  let rightHoleLength, leftHoleLength, holeLength;
  let angle = GeometryUtils.angleBetweenTwoPointsAndOrigin(x1, y1, x2, y2);
  let baseSpace = 20;
  let base_item = GeometryUtils.getAllItemSpecified(scene, catalog, 'Base');
  const l2 = {x: x1, y: y1};
  const l3 = {x: x2, y: y2};
  const isSnapped = (itemRect) => {
    const r0 = itemRect.rect[0];
    const r1 = itemRect.rect[1];
    const r2 = itemRect.rect[2];
    const r3 = itemRect.rect[3];
    let delta23 = GeometryUtils.verticesDistance(l2, r3) + GeometryUtils.verticesDistance(r3, r2) + GeometryUtils.verticesDistance(r2, l3) - GeometryUtils.verticesDistance(l3, l2);
    if(delta23 < 0.1) {
      return true;
    } else {
      return false;
    }
  }
  // base cabinet
  let baseRuler = [];
  let base_posArray = [];
  if(base_item.cur) {
    base_item.others.push(base_item.cur);
  }
  base_item.others.forEach(itemRect => {
    const r2 = itemRect.rect[2];
    const r3 = itemRect.rect[3];
    if(isSnapped(itemRect)) {
      base_posArray.push(r2);
      base_posArray.push(r3);
    }
  })
  base_posArray.push(l2);
  base_posArray.push(l3);
  let base_posAndwidth = [];
  if(Math.abs(angle)  === 0 || Math.abs(angle) === 180){
    base_posArray.sort((a, b) => {return a.x-b.x});
    if(base_posArray.length > 2) {
      for (let index = 0; index < base_posArray.length - 1; index++) {
        const element = base_posArray[index];
        base_posAndwidth.push({pos: element, length: base_posArray[index + 1].x - element.x});
      }
    }
  } else {
    base_posArray.sort((a, b) => {return a.y-b.y});
    if(base_posArray.length > 2) {
      for (let index = 0; index < base_posArray.length - 1; index++) {
        const element = base_posArray[index];
        base_posAndwidth.push({pos: element, length: base_posArray[index + 1].y - element.y});
      }
    }
  }
  if(!showBaseCabinetMeasure || base_posArray.length <= 2) {
    baseSpace -= 20;
  }
  baseRuler = base_posAndwidth.map(pAw => {
    if(pAw.length > 1){
      if(Math.abs(angle) === 0 || Math.abs(angle) === 180) {
        if (x1 - x2 > 0){
            return (
              <g>
                <line x1={pAw.pos.x + pAw.length} y1={pAw.pos.y} x2={pAw.pos.x + pAw.length} y2={pAw.pos.y-thickness/2 - baseSpace} style={STYLE} />
                <Ruler key={4} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={pAw.length} transform={`translate(${pAw.pos.x}, ${pAw.pos.y-thickness/2 - baseSpace} ) rotate(${angle+180}, 0, 0)`} />
                <line x1={pAw.pos.x} y1={pAw.pos.y} x2={pAw.pos.x} y2={pAw.pos.y-thickness/2 - baseSpace} style={STYLE} />
              </g>
            )
          } else {
            return (
              <g>
                <line x1={pAw.pos.x + pAw.length} y1={pAw.pos.y} x2={pAw.pos.x + pAw.length} y2={pAw.pos.y+thickness/2 + baseSpace} style={STYLE} />
                <Ruler key={4} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={pAw.length} transform={`translate(${pAw.pos.x}, ${pAw.pos.y+thickness/2 + baseSpace} ) rotate(${angle}, 0, 0)`} />
                <line x1={pAw.pos.x} y1={pAw.pos.y} x2={pAw.pos.x} y2={pAw.pos.y + thickness/2 + baseSpace} style={STYLE} />
              </g>
            )
          }
      } else {
        if(y1 - y2 > 0){
          return (
            <g>
              <line x1={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI)} y1={pAw.pos.y} x2={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) + baseSpace} y2={pAw.pos.y} style={STYLE} />
              <Ruler key={5} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={pAw.length} transform={`translate(${pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) + baseSpace}, ${pAw.pos.y + pAw.length} ) rotate(${-angle * Math.sin(angle/180*Math.PI)}, 0, 0)`} />
              <line x1={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI)} y1={pAw.pos.y + pAw.length} x2={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) + baseSpace} y2={pAw.pos.y + pAw.length} style={STYLE} />
            </g>
          )
        } else {
          return (
            <g>
              <line x1={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI)} y1={pAw.pos.y} x2={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) - baseSpace} y2={pAw.pos.y} style={STYLE} />
              <Ruler key={5} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={pAw.length} transform={`translate(${pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) - baseSpace}, ${pAw.pos.y} ) rotate(${angle * Math.sin(angle/180*Math.PI)}, 0, 0)`} />
              <line x1={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI)} y1={pAw.pos.y + pAw.length} x2={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) - baseSpace} y2={pAw.pos.y + pAw.length} style={STYLE} />
            </g>
          )
        }
      }
    }
  });
  let middleSpace = baseSpace + 20;
  let doorSpace = middleSpace + 20;
  let renderedHoles = line.holes.map(holeID => {
    let hole = layer.holes.get(holeID);
    let startAt = length * hole.offset;
    let renderedHole = catalog.getElement(hole.type).render2D(hole, layer, scene);
    if(compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x){
      return (
        <g
          key={holeID}
          transform={`translate(${startAt}, -3.5)`}
          data-element-root
          data-prototype={hole.prototype}
          data-id={hole.id}
          data-selected={hole.selected}
          data-layer={layer.id}
        >
          {renderedHole}
        </g>
      );
    }else{
      return (
        <g
          key={holeID}
          transform={`translate(${startAt}, 3.5)`}
          data-element-root
          data-prototype={hole.prototype}
          data-id={hole.id}
          data-selected={hole.selected}
          data-layer={layer.id}
        >
          {renderedHole}
        </g>
      );
    }
    
  });

  let renderedLine = catalog.getElement(line.type).render2D(line, layer);
  let holeTemp = [];
  let renderedRuler = [];
  let renderedAllRuler = [];
  let renderedHoleRuler = [];
  let renderedLeftHoleRuler = [];
  let renderedRightHoleRuler = [];
  let holeRuler = [0, length];
  if(!line.holes.size || !showWindowDoorMeasure) {
    doorSpace -= 20;
  }
  line.holes.forEach(holeID => {
    let hole = layer.holes.get(holeID);
    let startAt = length * hole.offset;
    let holeWidth = hole.properties.toJS().width.length;
    if( hole.selected ) {
      holeRuler.push(startAt - holeWidth / 2);
      holeRuler.push(startAt + holeWidth / 2);
      holeTemp = hole;
    }
  });
  holeRuler = holeRuler.sort((a, b) => a - b);

  let allRuler = [0], holeData = [];
  let lineHoles = line.holes.sortBy(holeID => {
    let hole = layer.holes.get(holeID);
    return hole.offset;
  });
  if (line.selected) {
    lineHoles.forEach(holeID => {
      let hole = layer.holes.get(holeID);
      let startAt = length * hole.offset;
      let holeWidth = hole.properties.toJS().width.length;
      allRuler.push(startAt - holeWidth / 2);
      allRuler.push(startAt + holeWidth / 2);
      holeData[
        `${startAt - holeWidth / 2}_${startAt + holeWidth / 2}`
      ] = holeID;
    });
    allRuler.push(length);
  }

  if (holeRuler.length > 2) {
    for (let index = 1; index < holeRuler.length; index++) {
      const element = holeRuler[index] - holeRuler[index - 1];
      if(compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x) {
        renderedHoleRuler.push(
          <g>
            <line x1={holeRuler[index - 1]} y1={half_thickness + doorSpace} x2={holeRuler[index - 1]} y2={half_thickness} style={STYLE} />
            <Ruler key={index} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={element} transform={`translate(${holeRuler[index - 1] + element}, ${half_thickness + doorSpace} ) rotate(180,0,0)`} />
            <line x1={holeRuler[index - 1]+element} y1={half_thickness + doorSpace} x2={holeRuler[index - 1]+element} y2={half_thickness} style={STYLE} />
          </g>
        )
      } else {
        renderedHoleRuler.push(
          <g>
            <line x1={holeRuler[index - 1]} y1={half_thickness + doorSpace} x2={holeRuler[index - 1]} y2={half_thickness} style={STYLE} />
            <Ruler key={index} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={element} transform={`translate(${holeRuler[index - 1] }, ${half_thickness + doorSpace} ) `} />
            <line x1={holeRuler[index - 1]+element} y1={half_thickness + doorSpace} x2={holeRuler[index - 1]+element} y2={half_thickness} style={STYLE} />
          </g>
        )
      }
    }
    leftHoleLength = holeRuler[1] - holeRuler[0];
    holeLength = holeRuler[2] - holeRuler[1];
    rightHoleLength = holeRuler[3] - holeRuler[2];
    if (compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x) {
      renderedLeftHoleRuler.push(<Ruler key={0} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={leftHoleLength} transform={`translate(${holeRuler[0]}, ${half_thickness - doorSpace} )`} />);
      renderedHoleRuler.push(<Ruler key={1} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={holeLength} transform={`translate(${holeRuler[1]}, ${half_thickness - doorSpace} )`} />);
      renderedRightHoleRuler.push(<Ruler key={2} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={rightHoleLength} transform={`translate(${holeRuler[2]}, ${half_thickness - doorSpace} )`} />);
    } else {
      renderedLeftHoleRuler.push(<Ruler key={0} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={leftHoleLength} transform={`translate(${holeRuler[0]}, ${half_thickness + doorSpace} )`} />);
      renderedHoleRuler.push(<Ruler key={1} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={holeLength} transform={`translate(${holeRuler[1]}, ${half_thickness + doorSpace} )`} />);
      renderedRightHoleRuler.push(<Ruler key={2} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={rightHoleLength} transform={`translate(${holeRuler[2]}, ${half_thickness + doorSpace} )`} />);
    }
  }
    // wall cabinet
    let wallSpace = doorSpace + 20;
    let wall_item = GeometryUtils.getAllItemSpecified(scene, catalog, 'Wall');
    let wallRuler = [];
    let wall_posArray = [];
    if(wall_item.cur) {
      wall_item.others.push(wall_item.cur);
  }
    wall_item.others.forEach(itemRect => {
    const r2 = itemRect.rect[2];
    const r3 = itemRect.rect[3];
      if(isSnapped(itemRect)) {
        wall_posArray.push(r2);
        wall_posArray.push(r3);
    }
  })
    wall_posArray.push(l2);
    wall_posArray.push(l3);
    let wall_posAndwidth = [];
  if(Math.abs(angle)  === 0 || Math.abs(angle) === 180){
      wall_posArray.sort((a, b) => {return a.x-b.x});
      if(wall_posArray.length > 2) {
        for (let index = 0; index < wall_posArray.length - 1; index++) {
          const element = wall_posArray[index];
          wall_posAndwidth.push({pos: element, length: wall_posArray[index + 1].x - element.x});
      }
    }
  } else {
      wall_posArray.sort((a, b) => {return a.y-b.y});
      if(wall_posArray.length > 2) {
        for (let index = 0; index < wall_posArray.length - 1; index++) {
          const element = wall_posArray[index];
          wall_posAndwidth.push({pos: element, length: wall_posArray[index + 1].y - element.y});
      }
    }
  }
    if(!showWallCabinetMeasure || wall_posArray.length <= 2) {
      wallSpace -= 20;
    }
    wallRuler = wall_posAndwidth.map(pAw => {
    if(pAw.length > 1){
      if(Math.abs(angle) === 0 || Math.abs(angle) === 180) {
          if (x1 - x2 > 0){
              return (
                <g>
                  <line x1={pAw.pos.x + pAw.length} y1={pAw.pos.y} x2={pAw.pos.x + pAw.length} y2={pAw.pos.y-thickness/2 - wallSpace} style={STYLE} />
                  <Ruler key={4} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={pAw.length} transform={`translate(${pAw.pos.x}, ${pAw.pos.y-thickness/2 - wallSpace} ) rotate(${angle+180}, 0, 0)`} />
                  <line x1={pAw.pos.x} y1={pAw.pos.y} x2={pAw.pos.x} y2={pAw.pos.y-thickness/2 - wallSpace} style={STYLE} />
                </g>
              )
          } else {
              return (
                <g>
                  <line x1={pAw.pos.x + pAw.length} y1={pAw.pos.y} x2={pAw.pos.x + pAw.length} y2={pAw.pos.y+thickness/2 + wallSpace} style={STYLE} />
                  <Ruler key={4} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={pAw.length} transform={`translate(${pAw.pos.x}, ${pAw.pos.y+thickness/2 + wallSpace} ) rotate(${angle}, 0, 0)`} />
                  <line x1={pAw.pos.x} y1={pAw.pos.y} x2={pAw.pos.x} y2={pAw.pos.y + thickness/2 + wallSpace} style={STYLE} />
                </g>
              )
          }
      } else {
          if(y1 - y2 > 0){
            return (
              <g>
                <line x1={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI)} y1={pAw.pos.y} x2={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) + wallSpace} y2={pAw.pos.y} style={STYLE} />
                <Ruler key={5} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={pAw.length} transform={`translate(${pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) + wallSpace}, ${pAw.pos.y + pAw.length} ) rotate(${-angle * Math.sin(angle/180*Math.PI)}, 0, 0)`} />
                <line x1={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI)} y1={pAw.pos.y + pAw.length} x2={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) + wallSpace} y2={pAw.pos.y + pAw.length} style={STYLE} />
              </g>
            )
          } else {
            return (
              <g>
                <line x1={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI)} y1={pAw.pos.y} x2={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) - wallSpace} y2={pAw.pos.y} style={STYLE} />
                <Ruler key={5} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={pAw.length} transform={`translate(${pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) - wallSpace}, ${pAw.pos.y} ) rotate(${angle * Math.sin(angle/180*Math.PI)}, 0, 0)`} />
                <line x1={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI)} y1={pAw.pos.y + pAw.length} x2={pAw.pos.x - thickness/2* Math.sin(angle/180*Math.PI) - wallSpace} y2={pAw.pos.y + pAw.length} style={STYLE} />
              </g>
            )
          }
        }
      }
    });
    let lineSpace = wallSpace + 20;
  if (compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x) {
    renderedRuler.push(
      <g>
        <line x1={length} y1={-(half_thickness + lineSpace)} x2={length} y2={-(half_thickness)} style={STYLE} />
        <Ruler key={0} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={length} transform={`translate(0, ${-(half_thickness + lineSpace)})`} />
        <line x1={0} y1={-(half_thickness + lineSpace)} x2={0} y2={-(half_thickness)} style={STYLE} />
      </g>
    );
  }else{
    renderedRuler.push(
      <g>
        <line x1={0} y1={half_thickness + lineSpace} x2={0} y2={half_thickness} style={STYLE} />
        <Ruler key={0} layer={layer} unit={scene.unit} rulerUnit={scene.rulerUnit} length={length} transform={`translate(0, ${half_thickness + lineSpace})`} />
        <line x1={length} y1={half_thickness + lineSpace} x2={length} y2={half_thickness} style={STYLE} />
      </g>
    );
  }

  if (allRuler.length > 2) {
    allRuler.forEach((rulerData, index) => {
      if (!Number.isNaN(allRuler[index + 1] - allRuler[index])) {
        let dataProtoType = 'ruler';
        let dataId = holeData[`${allRuler[index]}_${allRuler[index + 1]}`];
        if (!dataId) {
          let holeID1 = holeData[`${allRuler[index - 1]}_${allRuler[index]}`];
          let holeID2 =
            holeData[`${allRuler[index + 1]}_${allRuler[index + 2]}`];
          if (holeID1 && holeID2) {
            dataId = `${holeID1},${holeID2}`;
            dataProtoType = 'twoHoleRuler';
          } else if (holeID1) {
            dataId = holeID1;
            dataProtoType = 'rightHoleRuler';
          } else if (holeID2) {
            dataId = holeID2;
            dataProtoType = 'leftHoleRuler';
          }
        } else {
          dataProtoType = 'rulerHole';
        }
        renderedAllRuler.push(
          <g
            key={`allRuler_${index}`}
            transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
            data-element-root
            data-prototype={dataProtoType}
            data-id={dataId}
            data-selected={line.selected}
            data-layer={layer.id}
            data-length={allRuler[index + 1] - allRuler[index]}
          >
            {showWallLengthMeasure ? (
              <Ruler
                key={`allRuler_${index}`}
                layer = {layer}
                unit={scene.unit}
                rulerUnit={scene.rulerUnit}
                length={allRuler[index + 1] - allRuler[index]}
                transform={`translate(${allRuler[index]}, ${
                  half_thickness + compareVertices(vertex0, vertex1) >= 0 &&
                  vertex0.x !== vertex1.x
                    ? -15
                    : 15
                } )`}
              />
            ) : null}
          </g>
        );
      }
    });
  }

  if (compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x){
    return (
      <g>
        {showWallLengthMeasure ? renderedAllRuler : null}
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'ruler'}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={length}
        >
          { showWallLengthMeasure ? renderedRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'leftHoleRuler'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={leftHoleLength}
        >
          { showWallLengthMeasure ? renderedLeftHoleRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'rulerHole'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={holeLength}
        >
          { showWindowDoorMeasure ? renderedHoleRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'rightHoleRuler'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={rightHoleLength}
        >
          { showWallLengthMeasure ? renderedRightHoleRuler : null }
        </g>
        { showBaseCabinetMeasure ? baseRuler : null }
        { showWallCabinetMeasure ? wallRuler : null }
        <g
          data-element-root
          data-prototype={line.prototype}
          data-id={line.id}
          id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          style={line.selected ? { cursor: 'move' } : {}}
        >
          { renderedLine }
          <g 
            transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          >
            {/* <text x={length/2} y={7} transform={`scale(1,-1) rotate(${0})`} style={STYLE_TEXT}>{num}</text> */}
            {renderedHoles}
          </g>
        </g>
        {line.selected && <g
          key={1}
          transform={`translate(${x1 + 10}, ${y1 + 10})`}
          style={{ cursor: 'pointer' }}
          data-element-root
          data-prototype={line.prototype}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-part="duplicate"
        >
          <defs>
            <filter id="shadow">
              <feDropShadow dx="0" dy="0" stdDeviation="0.6"></feDropShadow>
            </filter>
          </defs>
          <rect
            rx="4"
            ry="4"
            y="20"
            height="22"
            width="22"
            fill="white"
            filter="url(#shadow)"
          />
          <image
            href="/assets/img/svg/duplicate.svg"
            x="3"
            y="-39"
            height="16"
            width="16"
            style={{ transform: 'rotateX(180deg)' }}
          />
        </g>}
        {line.selected && <g
          key={2}
          transform={`translate(${x1 + 10}, ${y1})`}
          style={{ cursor: 'pointer' }}
          data-element-root
          data-prototype={line.prototype}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-part="remove"
        >
          <rect
            rx="4"
            ry="4"
            y="5"
            height="22"
            width="22"
            fill="white"
            filter="url(#shadow)"
          />
          <image
            href="/assets/img/svg/delete.svg"
            x="3"
            y="-24"
            height="16"
            width="16"
            style={{ transform: 'rotateX(180deg)' }}
          />
        </g>}
      </g>
    );
  }else{
    return (
      <g>
        {showWallLengthMeasure ? renderedAllRuler : null}
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'ruler'}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={length}
        >
          { showWallLengthMeasure ? renderedRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'leftHoleRuler'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={leftHoleLength}
        >
          { showWallLengthMeasure ? renderedLeftHoleRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'rulerHole'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={holeLength}
        >
          { showWindowDoorMeasure ? renderedHoleRuler : null }
        </g>
        <g
          transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          data-element-root
          data-prototype={'rightHoleRuler'}
          data-id={holeTemp.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-length={rightHoleLength}
        >
          { showWallLengthMeasure ? renderedRightHoleRuler : null }
        </g>
        { showBaseCabinetMeasure ? baseRuler : null }
        { showWallCabinetMeasure ? wallRuler : null }
        <g
          data-element-root
          data-prototype={line.prototype}
          data-id={line.id}
          id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          style={line.selected ? { cursor: 'move' } : {}}
        >
          { renderedLine }
          <g 
            transform={`translate(${x1}, ${y1}) rotate(${angle}, 0, 0)`}
          >
            {/* <text x={length/2} y={0} transform={`scale(1,-1) rotate(${0})`} style={STYLE_TEXT}>{num}</text> */}
            {renderedHoles}
          </g>
        </g>
        {line.selected && <g
          key={1}
          transform={`translate(${x1 + 10}, ${y1 + 10})`}
          style={{ cursor: 'pointer'}}
          data-element-root
          data-prototype={line.prototype}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-part="duplicate"
        >
          <defs>
            <filter id="shadow">
              <feDropShadow dx="0" dy="0" stdDeviation="0.6"></feDropShadow>
            </filter>
          </defs>
          <rect
            rx="4"
            ry="4"
            y="20"
            height="22"
            width="22"
            fill="white"
            filter="url(#shadow)"
          />
          <image
            href="/assets/img/svg/duplicate.svg"
            x="3"
            y="-39"
            height="16"
            width="16"
            style={{ transform: 'rotateX(180deg)' }}
          />
        </g>}
        {line.selected && <g
          key={2}
          transform={`translate(${x1 + 10}, ${y1})`}
          style={{ cursor: 'pointer' }}
          data-element-root
          data-prototype={line.prototype}
          data-id={line.id}
          data-selected={line.selected}
          data-layer={layer.id}
          data-part="remove"
        >
          <rect
            rx="4"
            ry="4"
            y="5"
            height="22"
            width="22"
            fill="white"
            filter="url(#shadow)"
          />
          <image
            href="/assets/img/svg/delete.svg"
            x="3"
            y="-24"
            height="16"
            width="16"
            style={{ transform: 'rotateX(180deg)' }}
          />
        </g>}
      </g>
    );
  }
  

}

Line.propTypes = {
  line: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};
